import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
    en: {
        translation: {
            "aboutus.team-title": "Strong as a team",
            "aboutus.card.founder": "Founder",
            "aboutus.card.founderceo": "Founder & Chief Executive Officer",
            "header.aboutus": "About us",
            "header.service": "Service",
            "header.contact": "Contact",
            "footer.impressum": "Legal Notice",
            "footer.privacy": "Privacy",
            "footer.addlinks": "Further links",
            "footer.legal": "Legal",
            "error.404.title": "Error 404",
            "error.404.text": "Page not found :("
        }
    },
    de: {
        translation: {
            "aboutus.team-title": "Stark als Team",
            "aboutus.card.founder": "Gründer",
            "aboutus.card.founderceo": "Gründer & Geschäftsführer",
            "header.aboutus": "Über uns",
            "header.service": "Service",
            "header.contact": "Kontakt",
            "footer.impressum": "Impressum",
            "footer.privacy": "Datenschutz",
            "footer.legal": "Rechtliches",
            "footer.addlinks": "Weitere Links",
            "error.404.title": "Fehler 404",
            "error.404.text": "Seite nicht gefunden :("
        }
    }
};

const getlang = () => {
    const langs = ["de","en"];
    let selected = localStorage.getItem("lang");
    return (~langs.indexOf(selected)) ? selected : "de";
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getlang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
