import { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            <div className="section">
                <h2>Contact</h2>
                Lorem ipsum ...
            </div>
        )
    }
}

export default Contact;