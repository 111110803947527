import { Component } from 'react';
import './Header.css';
import logo1 from '../../img/logo.png';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const changeLang = function(lng) {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
}

class Header extends Component {

    componentDidMount() {
        document.addEventListener("scroll", function() {

            if(window.scrollY > window.innerHeight*0.4 - 50) {
                document.querySelector("header").classList.add("scrolled");
            }
            else {
                document.querySelector("header").classList.remove("scrolled");
            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <header>
                <div className="header-wrapper">
                    <div id="logo">
                        <a href={"/"}><img src={logo1} alt={logo1} /></a>
                    </div>

                    <input type="checkbox" id="nav-toggle" className="nav-toggle" />
                    <nav>
                        <ul>
                            <li><a href={"/team"}>{t("header.aboutus")}</a></li>
                            <li><a href={"/service"}>{t("header.service")}</a></li>
                            <li><a href={"/contact"}>{t("header.contact")}</a></li>
                            <li className="lang-flags">
                                <input type="image" alt="DE" onClick={() => changeLang('de')} src={`data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDUgMyI+DQo8cGF0aCBkPSJtMCwwaDV2M2gtNXoiLz4NCjxwYXRoIGZpbGw9IiNkMDAiIGQ9Im0wLDFoNXYyaC01eiIvPg0KPHBhdGggZmlsbD0iI2ZmY2UwMCIgZD0ibTAsMmg1djFoLTV6Ii8+DQo8L3N2Zz4NCg==`} style={{"height":"1rem"}} />
                                &ensp;
                                <input type="image" alt="EN" onClick={() => changeLang('en')} src={`data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgMzAiIGhlaWdodD0iNjAwIj4NCjxkZWZzPg0KPGNsaXBQYXRoIGlkPSJ0Ij4NCjxwYXRoIGQ9Im0zMCwxNWgzMHYxNXp2MTVoLTMwemgtMzB2LTE1enYtMTVoMzB6Ii8+DQo8L2NsaXBQYXRoPg0KPC9kZWZzPg0KPHBhdGggZmlsbD0iIzAwMjQ3ZCIgZD0ibTAsMHYzMGg2MHYtMzB6Ii8+DQo8cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNiIgZD0ibTAsMGw2MCwzMG0wLTMwbC02MCwzMCIvPg0KPHBhdGggc3Ryb2tlPSIjY2YxNDJiIiBzdHJva2Utd2lkdGg9IjQiIGQ9Im0wLDBsNjAsMzBtMC0zMGwtNjAsMzAiIGNsaXAtcGF0aD0idXJsKCN0KSIvPg0KPHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEwIiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjxwYXRoIHN0cm9rZT0iI2NmMTQyYiIgc3Ryb2tlLXdpZHRoPSI2IiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjwvc3ZnPg0K`} style={{"height":"1rem"}} />
                            </li>
                        </ul>
                    </nav>

                    <label htmlFor="nav-toggle"><div className="menu-icon"></div></label>
                </div>
            </header>
        )
    }
}

export default withTranslation()(Header);