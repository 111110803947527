import { Component } from 'react';
import './Card.css';

import { withTranslation } from "react-i18next";

class Card extends Component {


    render() {
        const { t } = this.props;
        return (
            <div className="rcard">
                <div className="rcard-img"><img src={this.props.pic} alt="Profile" /></div>
                <div className="rcard-description">
                    <div className="rcard-title">
                        <b>{this.props.name}</b>
                        <p>{t(`${this.props.role}`)}</p>
                    </div>
                    <p>{this.props.description}</p>
                    <div className="tags">
                        {this.props.tags.join(", ")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Card);