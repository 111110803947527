import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Team from './components/pages/Team';
import Service from './components/pages/Service';
import Contact from './components/pages/Contact';
import Error from './components/pages/Error';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function App({t}) {
  return (
      <div id="App">
        <Header />
        <main>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <div className="section">
                            <div>Security over Security</div>
                        </div>
                        <div className="section">Welcome! Lorem ipsum ...</div>
                    </Route>
                    <Route exact path="/team">
                        <div className="section">{t("header.aboutus")}</div>
                        <Team />
                    </Route>
                    <Route exact path="/service">
                        <div className="section">{t("header.service")}</div>
                        <Service />
                    </Route>
                    <Route exact path="/contact">
                        <div className="section">{t("header.contact")}</div>
                        <Contact />
                    </Route>
                    <Route path="*">
                        <div className="section">404</div>
                        <Error />
                    </Route>
                </Switch>
            </Router>
        </main>
        <Footer />
      </div>
  );
}

export default withTranslation()(App);
