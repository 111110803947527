import { Component } from 'react';

class Service extends Component {

    render() {
        return (
            <div className="section">
                <h2>Service</h2>
                Lorem ipsum ...
            </div>
        )
    }
}

export default Service;