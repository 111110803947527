import { Component } from 'react';
import './Footer.css';

import { withTranslation } from "react-i18next";

class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <footer>
                <div className="footer-wrapper">
                    <div className="col">
                        <h3>{t("footer.addlinks")}</h3>
                        <ul>
                            <li><a href="https://github.com/Lev9L-Team" target="_blank" rel="noreferrer">Github: CTF-Team</a></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>{t("footer.legal")}</h3>
                        <p><a href="/impressum.html" target="_blank">{t("footer.impressum")}</a></p>
                        <p><a href="/privacy.html" target="_blank">{t("footer.privacy")}</a></p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer);
