import { Component } from 'react';
import { withTranslation } from "react-i18next";

class Error extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="section">
                <h2>{t("error.404.title")}</h2>
                {t("error.404.text")}
            </div>
        )
    }
}

export default withTranslation()(Error);